<template>
  <div v-if="(pano.gpsLat!=0||pano.gpsLng!=0)&&pano.country" class="text-shadow local" style="font-size:10px">
    <i title="地图坐标" class="fas fa-map-marker-alt"></i>
    <span v-if="pano.country!='中华人民共和国'&&pano.country!='中国'" style="white-space: nowrap;">{{pano.country}}</span>
    <span style="white-space: nowrap;">{{pano.province}}</span>
    <span style="white-space: nowrap;">{{pano.city}}</span>
    <span style="white-space: nowrap;">{{pano.district}}</span>
    <span style="white-space: nowrap;">{{pano.street}}</span>
  </div>
  <div v-else>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      }
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
  .local {
    opacity: 0.7;
    max-height: 45px;
  }
</style>
